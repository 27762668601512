<template>
  <div>
    <CommunitiesComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import CommunitiesComp from '@/components/desktop/vitaeHealthHouse/CommunitiesComp'

  export default {
    name: 'Communities',
    props: ['mobile'],
    components: {
      CommunitiesComp
    },
  }
</script>
