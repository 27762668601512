<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'contentMobile1' : 'content pb-10'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <div v-html="$t('text1')" v-bind:class="mobile ? 'textGrey' : 'textGrey pr-10'"></div>
      </v-col>
      <v-col cols="6" v-if="!mobile">
        <div v-html="$t('text2')" class="textBlue"></div>
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'contentMobile1' : 'content'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <v-img
            alt="Sport"
            :src="require('@/assets/vitaeHealthHouse/sport3.png')"
            contain
            v-bind:height="mobile ? 250 : 350"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <div v-html="$t('text2')" class="textBlue pb-5" v-if="mobile"></div>
        <div v-html="$t('text3')" class="textBlue"></div>
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'contentMobile1 pb-10' : 'content pb-16'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <div v-html="$t('text4')" v-bind:class="mobile ? 'textBlue' : 'textBlue pr-10'"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <v-img
            alt="Sport"
            :src="require('@/assets/vitaeHealthHouse/sport4.png')"
            contain
            v-bind:height="mobile ? 250 : 350"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'CommunitiesComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "The communities",
    "text1": "The association, within the framework of the objectives of sports and health centers, is the bearer of a project for the development of a territorial “sport and health” competence for the inhabitants of the municipalities located in the Alpes-Maritimes and more particularly of the municipalities located in the basin. Cannes, Vençois and Grasse.",
    "text2": "<strong> In conjunction with the partner municipalities of the project, the association undertakes to: </strong> <br> <br> - Make every effort to accompany, support and develop suitable physical and / or sports activities 3 in accordance with regulatory texts.",
    "text3": "- To receive, assess, guide and support people with chronic pathologies or advanced in age inducing functional limitations, towards a sustainable practice. <br> In this context, it takes into account their motivation, life context and needs according to their state of health defined at the end of the medical check-up and of an evaluation of the physical condition; <br> <br> - To take into account the objectives or orientations defined by a medical prescription issued by a prescribing doctor in addition to care and / or to maintain a good quality of life. <br> <br> - To develop local APA in conjunction with professionals and jointly referenced municipal associations.",
    "text4": "<br> <br> <strong> The partner municipalities are committed to: </strong> <br> <br> -Promote and support the development of adapted physical activities in their territory. <br> <br> -Provide implements the strategies and means necessary according to their objectives and possibilities to facilitate access to the practice of local APA. <br> <br> - Recognize Vitaé Sport Santé as an expert partner « trusted third party » of the various actors of the Sport-Health community."
  },
  "fr": {
    "mainTitle": "Les collectivités",
    "text1": "L’association, dans le cadre des objectifs des Maisons Sport-Santé est porteuse d’un projet de développement d’une compétence «sport et santé» territoriale pour les habitants des communes situées dans les Alpes-Maritimes et plus particulièrement des communes situées dans le bassin Cannois, Vençois et Grassois.",
    "text2": "<strong>En lien avec les communes partenaires du projet, l’association s’engage à :</strong><br><br>  -Tout mettre en oeuvre pour accompagner, soutenir et développer les activités physiques et/ou sportives adaptées conformément aux textes règlementaires.",
    "text3": "- Recevoir, évaluer, orienter et accompagner les publics présentant des pathologies chroniques ou avancés en âge induisant des limitations fonctionnelles, vers une pratique pérenne.<br> Dans ce cadre, elle tient compte de leur motivation, contexte de vie et besoins selon leur état de santé défini à l’issue du bilan médical et d’une évaluation de la condition physique ;<br><br> - Tenir compte des objectifs ou orientations définis par une prescription médicale délivrée par un médecin prescripteur en complément de soin et/ou pour le maintien d’une bonne qualité de vie.<br><br> - Développer des APA de proximité en lien avec les professionnels et associations communales conjointement référencées.",
    "text4": "<br><br><strong>Les Communes partenaires s’engagent à :</strong> <br><br> - Promouvoir et accompagner le développement des activités physiques adaptées sur leur territoire.<br><br> - Mettre en oeuvre les stratégies et moyens nécessaires en fonction de leurs objectifs et possibilités pour faciliter l’accès à la pratique d’APA de proximité.<br><br> - Reconnaitre Vitaé Sport Santé comme partenaire expert « tiers de confiance » des différents acteurs de la communauté Sport-Santé."
  }
}
</i18n>

<style scoped>

.contentMobile1 {
  font-size: 14px;
}

.textGrey {
  color: #808080;
}

.textBlue {
  color: #57939E;
}

</style>
